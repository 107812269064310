/*============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/
@import url('https://fonts.googleapis.com/css?family=Exo:100,200,300,400,500,600,700,800,900');
@import "../css/reset";
@import "sass-lib";

body {  
    color: #000;
    font-size: 12px;
    font-family: 'Exo', sans-serif; 
    position: relative;
    overflow-x: hidden;
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    @include borderRadius(5px);
}
textarea {resize: none;}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
p {font-size: 20px; line-height: 30px; margin-bottom: 25px;  }
h1 { font-size: 40px; text-transform: uppercase;}
h2 { font-size: 28px; }
h3 { font-size: 32px; }
h4 { font-size: 25px; } 
h5 { font-size: 22px; font-weight: 600;} 
h6 { font-size: 14px; }
/* structure 
----------------------------------------------------------------------------------------------*/
section { position: relative; }
.container {width: 1600px; margin: 0 auto; position: relative;}
.wrapper {width: 1200px;margin: 0 auto;}

/* global
----------------------------------------------------------------------------------------------*/
.after_clear:after,
.wrapper:after,
section:after,
.container:after{clear:both;display: block;content:" "}

.before_clear:before{clear:both;display: block;content:" "}
.a-left{text-align: left}
.a-right{text-align: right}
.a-center{text-align: center}
.italic { font-style: italic; }
.bold { font-weight: bold; }
.regular { font-weight: lighter; }
.fl { float: left; }
.fr { float: right; }
.clear { clear: both; }

.btn {
    font-size: 18px; color: #fff; font-weight: 700; line-height: 40px; display: block; @include transition(all ease-out 0.2s);
    text-align: center; background: $c_red; text-transform: uppercase; @include borderRadius(5px); cursor: pointer;
    &.white {
        border: 1px solid #000; background: transparent; color: #000;
        &:hover {
            color: #fff; background: #000; @include transition(all ease-out 0.2s);
        }
    }
    &:hover {
        background: #cd0e0e;@include transition(all ease-out 0.2s); color: #fff;
    }
}


/* header
----------------------------------------------------------------------------------------------*/
header {
    position: absolute; top: 0; left: 0; right: 0; z-index: 9; padding-top: 20px; height: 100px;
    @include boxSizing(border-box);
    .logo {float: left;}
    .main_menu {
        float: right; @extend .after_clear;
        nav {
            float: left;
            ul {
                li {
                    float: left; margin: 0 17px;
                    a {
                        font-size: 18px; font-weight: 800; color: #000; text-transform: uppercase;
                        padding-top: 20px; display: block; position: relative; 
                        &:before {
                            content:''; display: block; position: absolute; top: 0; right: 0; left: 0; width: 0;
                            height: 4px; background: $c_red; margin: auto; @include transition(width linear 0.2s);
                        }
                        &.active {
                            &:before {width: 85%; @include transition(width linear 0.2s);}
                        }
                    }
                    &.prod {
                        >a {
                            position: relative;
                            &:after {
                                content:''; width: 0; position: absolute; left: 0; right: 0; margin: auto; bottom: 0; opacity: 0;
                                border-right: 10px solid transparent; border-left: 10px solid transparent; border-bottom: 20px solid #000;
                            }
                            &.act {
                                &:after {opacity: 1;}
                            }
                        }
                        /*&:hover {
                            >a {
                                &:after {
                                    opacity:1;@include transition(opacity linear 0.2s);
                                }
                            }
                            .sub_prod {
                                display: block; @include transition(all  linear 0.2s);
                            }
                        }*/
                    }
                    >a {padding-bottom: 38px;}
                    &:hover {
                        >a {
                            &:before {width: 85%; @include transition(width linear 0.2s);}
                        }
                    }
                    &.mn_mobile {display: none;}
                }
            }
            .mobile_menu {display: none;}
        }
        .cart {
            float: left; margin-left: 20px; padding-bottom: 33px;
            >a {
                display: block; width: 45px; height: 45px; border: 1px solid #000; @include borderRadius(50%); position: relative;
                span {
                    display: block; position: absolute; width: 29px; height: 29px; left: 0; right: 0; top: 0; bottom: 0;
                    margin: auto;
                    &.tot_cart {
                        width: 26px; line-height: 26px; color: #fff; background: $c_red; @include borderRadius(50%); height: 26px;
                        font-size: 14px; font-weight: 900; text-align: center; bottom: -15px; right: 0; top: auto; left: auto;
                    }
                }
            }
            .drop_cart {
                position: absolute; right: -30px; border-top: 6px solid $c_red; top: 95px; display: none;
                .box_cart {
                    position: relative; border: 3px solid $c_e1; background: #fff;
                    padding: 20px; border-top: 0; width: 345px;
                    h5 {font-size: 22px; font-weight: 700; padding-bottom: 20px; border-bottom: 1px solid $c_e1; }
                    .list_cart {
                        margin-top: 22px;
                        .cart_item {
                            @extend .after_clear; margin-bottom: 20px; position: relative;
                            .img {width: 78px; border: 1px solid $c_e1; float: left;}
                            .ket {
                                float: right; width: 250px;
                                p {
                                    margin: 0 0 8px; font-size: 16px; color: #000; line-height: 18px;
                                    &.prod_nm {color: $c_999;}
                                }
                                &:after {
                                    content: ''; width: 6px; height: 0px; background: $c_red; position: absolute; right: -23px; top: 0;
                                    bottom: 0; margin: auto; display: inline-block; @include transition(height linear 0.2s);
                                }
                            }
                            .rmove {
                                width: 18px; height: 17px; position: absolute; right: 0; top: 0; display: inline-block;
                                background: url('../images/material/remove_cart.png') no-repeat top center; cursor: pointer;
                                &:hover {
                                    background-position: bottom center;
                                }
                            }

                            &:last-child {margin-bottom: 0;}
                            &:hover {
                                .ket {
                                    &:after {height: 45px; @include transition(height linear 0.2s);}
                                }
                            }
                        }
                    }
                    .total {
                        margin: 25px 0 30px;
                        label {display: inline-block; width: 30%; text-transform: uppercase; font-size: 16px;}
                        span  {
                            width: 69%; color: #000; font-size: 22px; text-align: right; display: inline-block;
                            i {font-style: normal; font-size: 16px; display: inline-block; margin-right: 5px;}
                        }
                    }
                    .arr_bx {
                        display: inline-block; border-top: 6px solid $c_red;  border-left: 6px solid $c_red;
                        width: 24px; height: 24px; position: absolute; right: 30px; top: -19px;
                        @include transform(rotate(45deg));
                        &:after {
                            content:''; width: 24px; height: 24px; background: #fff; position: absolute; left: 0; right: 0;
                        }
                    }
                }
            }
        }
        .burger_mnu {display: none;}
    }
    &.home {
        .main_menu {
            nav {
                ul {
                    li {
                        a {color: #fff;}
                    }
                }
            }
            .cart {
                a {
                    border-color: #fff;
                    span {background-position: right center !important;}
                }

            }
        }
    }
}
.mobile_prod {display: none;}
.sub_prod {
    position: absolute; z-index: 999; display: none; left: 0; right: 0; top: 75px; @include transition(display  linear 0.2s);
    width: 1200px; margin: auto;
    .hov_product {
        border-top: 6px  solid #000;background: #fff; position: relative; height: 455px; overflow: hidden;
        @include boxShadow(0px 3px 20px 0px #aaa); cursor: pointer;-webkit-tap-highlight-color: rgba(0,0,0,0);@include appearance(none);
        &:focus {background: none;}        
        *{
            -webkit-tap-highlight-color: rgba(0,0,0,0);
        }
    }
    .close_tab {
        display: none; width: 24px; height: 24px;  background: url('../images/material/close_tab.png') no-repeat;
        position: absolute; top: 20px; right: 0;
    }
    .nav_tab {
        background: #000; width: 300px; margin: 0 auto; display: block; padding: 16px 10px; @extend .after_clear;
        position: relative;
        a {
            color: #fff; font-size: 15px;text-transform: uppercase; font-weight: 300; position: relative; padding-bottom: 0;
            float: left; width: 50%; display: block; text-align: center; line-height: 20px; padding-top: 0;
            &:first-child {
                &:after {
                    content:''; width: 1px; height: 20px; background: #fff; position: absolute; right: -3px;
                    top: 0; display: inline-block; border: none; left: auto;
                }
            }
            &:before {display: none;}
            &.active {
                font-weight: 600;
            }
            &:after {display: none;}
        }
    }
    .content_tab {
        a {
            padding-top: 0; padding-bottom: 0;
            &:before, &:after  {display: none;}
        }
        .tab_list {
             width: 960px; margin: 0 auto; display: block; padding: 22px 0 50px; display: none;
             h6 {
                font-size: 14px; font-weight: 300; text-align: center; text-transform: uppercase;
                margin-bottom: 30px;
                span {color: $c_red;}
            }
             .tabBrand {
                .slider01, .slider02 {
                    .brand {
                        .listBrand {
                            height: 281px;
                            li {float: left; width: 120px; margin: 0 0 20px; padding: 0 5px; @include boxSizing(border-box);}
                        }
                    }
                    .slick-arrow {
                        width: 34px; height: 31px; border: none; font-size: 0; cursor: pointer;  display: block; position: absolute;
                        top: 0; bottom: 0; margin: auto; cursor: pointer;
                        @include transition( background-position ease-out 0.2s); @include transform(rotate(-90deg));
                        &.slick-prev {
                            background: url('../images/material/arr_project_top.png') no-repeat bottom center; left: -70px;
                            /*&:hover {
                                background-position: bottom center; @include transition( background-position ease-out 0.2s);
                            }*/
                        }
                        &.slick-next {
                            background: url('../images/material/arr_project_btm.png') no-repeat top center; right: -70px;
                            /*&:hover {
                                background-position: top center;  @include transition( background-position ease-out 0.2s);
                            }*/
                        }
                    }
                }
             }
        }
    }
}

.sub_bike {
    background: #fff; padding: 20px 100px 20px 70px; position: absolute; top: 70px; display: none;
    margin: 0 -100px;
    .bike_list {height: 321px;}
    .bikes_sub {
        @extend .after_clear; display: none;
        .nme_bike {
            display: block; float: left; width: 190px; margin: 6% 0 0;
            img {width: auto; position: relative; z-index: 2; display: block; margin: 15px auto;}
            p {margin-bottom: -10px; position: relative;z-index: 3; text-align: center; font-size: 15px; text-transform: uppercase;}
            h6 {margin-bottom: 0; margin-top: 0; position: relative; z-index: 3;}
            .back {
                display: block; width: 34px; height: 31px; background: url("../images/material/arr_project_top.png") no-repeat top center;
                @include transform(rotate(-90deg)); position: relative; margin: 20px auto 0; @include transition( background-position linear 0.2s);
                cursor: pointer;
                &:hover {
                   @include transition( background-position linear 0.2s); background-position: bottom center; 
                }
            }
        }
        .listBike {
            display: block; float: right; width: 770px; padding: 15px 30px 0; border-left: 1px solid $c_e1;
             @include boxSizing(border-box);
            .slide_bike {width: 725px;}
            .bike {
                height: 305px;
                ul {
                    li {
                        float: left; width: 180px; text-align: center; padding: 0 10px; @include boxSizing(border-box);
                        margin: 0 0 25px;
                        a {
                            display: block; position: relative;
                            &:after {
                                content:''; background: rgba(255,255,255,0.7); display: block; position: absolute; left: 0; right: 0;
                                top: 0; bottom: 0; opacity: 1; @include transition(opacity ease-out 0.2s); border: none;
                            }
                            &:hover {
                                &:after {
                                    opacity: 0;@include transition(opacity ease-out 0.2s);
                                }
                            }
                        }
                        img {display: block; margin: 0 auto 10px;}
                        h5 {font-size: 12px;  color: #000; text-transform: uppercase;}
                    }
                }
            }
            .slick-arrow {
                width: 34px; height: 31px; border: none; font-size: 0; cursor: pointer;  display: block; position: absolute;
                margin: auto; cursor: pointer; right: -70px;
                @include transition( background-position ease-out 0.2s);
                &.slick-prev {
                    background: url('../images/material/arr_project_top.png') no-repeat top center; top: 35%; bottom: auto;
                    &:hover {
                        background-position: bottom center; @include transition( background-position ease-out 0.2s);
                    }
                }
                &.slick-next {
                    background: url('../images/material/arr_project_btm.png') no-repeat bottom center; bottom: 35%; top: auto; 
                    &:hover {
                        background-position: top center;  @include transition( background-position ease-out 0.2s);
                    }
                }
            }
        }
    }
}

/* middle
----------------------------------------------------------------------------------------------*/


.homepage {
    position: relative; height: 100vh; overflow: hidden;
    .homeSlide {
        .images {
            position: relative; height: 100vh;
            img {position: absolute; top: 50%; @include transform(translate(-50%,-50%)); min-width: 100%; max-width: none; left: 50%;}
        }
        .caption {
            position: absolute; top: 31%; left: 0; right: 0;
            h1 {
                font-size: 90px; font-weight: 900; color: #fff; font-style: italic;
                 position: relative; text-align: center;
                &.second {
                    color: #ffff39;
                }
            }
        }
        /*.slick-dots {
            position: absolute; right: 50px; top: 30%; bottom: 0; margin: auto;
            li {
                text-align: right;
                button {
                    border: none; position: relative;background: none;display: inline-block; text-align: right; line-height: 55px; height: 55px;
                    font-size: 16px;  color: #fff; font-weight: 200; font-style: italic; cursor: pointer; font-family: 'Exo', sans-serif;
                    @include transition(all ease-out 0.2s);
                    &:before {
                        content: '0'; font-size: 17px; color: #fff;  @include transition(all ease-out 0.2s);
                        display: inline-block;font-weight: 200px; font-style: italic;
                    }
                    &:after {
                        content: '-'; font-size: 16px;  display: inline-block;  color: #fff;
                        display: none; margin-left: 10px;  @include transition(all ease-out 0.2s);
                    }
                }
                &.slick-active,
                &:hover {
                    button {
                        font-size: 38px; font-weight: 900; @include transition(all ease-out 0.3s);
                        &:before {
                            font-size: 38px; font-weight: 900; @include transition(font-size ease-out 0.3s);
                        }
                        &:after {
                            display: inline-block; vertical-align: middle;  @include transition(all ease-out 0.3s);
                        }
                    }
                }
            }
        }*/
        .slick-dots {
            position: absolute; right: 50px; top: auto; bottom: 100px; margin: auto; left: 0; text-align: right;
            li {
                text-align: right; display: inline-block; margin: 0 5px;
                button {
                    border: none; position: relative;background: none;display: inline-block; text-align: right; width: 15px; height: 15px;
                    color: #fff; font-weight: 200; font-style: italic; cursor: pointer; font-family: 'Exo', sans-serif; font-size: 0;
                    @include transition(all ease-out 0.2s); background: rgba(255,255,255,0.4);
                }
                &.slick-active,
                &:hover {
                    button {
                        background: $c_red;
                    }
                }
            }
        }
    }
}

.page {
    padding: 150px 0 185px; @include boxSizing(border-box); min-height: 100vh;
    &.career {
        background: #ebebeb url('../images/banner/career_bg.jpg') no-repeat top center; min-height: 100vh; 
    }
    ul {
        li {
            position: relative; font-weight: 400; font-size: 18px;
            padding-left: 35px; margin-bottom: 15px;
            &:before {
                content:''; width: 8px; height: 8px; background: $c_red; position: absolute; left: 10px;
                top: p4x; @include borderRadius(50%);
            }
        }
    }
    &.contact {padding-top: 0;}
    &.news {
        background: url('../images/banner/bg_news.jpg') no-repeat top center;
    }
}
.bg_street {
    background: #fff url('../images/banner/bg_street.jpg') no-repeat top center;
    &.top {

        background-position: center -125px;
    }
}
.project {
    background: #F0F0F0 url('../images/banner/bg_project.jpg') no-repeat top center;
    &.list {
        background: url('../images/banner/bg_project_list.jpg') no-repeat top center;
        padding-top: 290px;
    }
}


.pg_title {
    font-size: 40px; padding-left: 35px; position: relative; color: #000; font-weight: 800; font-style: italic;
    text-transform: uppercase; margin-bottom: 25px;
    &:before {
        content:''; width: 12px; height: 12px; background: $c_red; @include borderRadius(50%); display: block;
        position: absolute; left: 10px; top: 0; bottom: 0; margin: auto;
    }
    &.rh {
        padding-left: 0; padding-right: 35px;
        &:before {display: none;}
        &:after {
            content:''; width: 12px; height: 12px; background: $c_red; @include borderRadius(50%); display: block;
            position: absolute; right: 0px; top: 0; bottom: 0; margin: auto;
        }
    }
}
.text {
    p {font-size: 20px; line-height: 30px; margin-bottom: 25px; font-weight: 300;}
}
.sec_about {
    padding-bottom: 80px;
    .img {float: left; width: 62.5%;}
    .about {float: right; width: 35%; padding-top: 87px;}
}
.sec_milestones {
    padding-bottom: 200px;
    .list_year {
        .year {

        }
    }
    .miles_content {
        .pg_title {margin-bottom: 45px;}
        .slide_miles {
            .miles {
                @extend .after_clear;
                .images {
                    width: 50%; float: left;
                    img {display: block;}
                    p.ket_year {
                        margin: 22px 0 0; font-size: 25px; font-weight: 700; text-align: right; line-height: 20px;
                        @extend .after_clear;
                        span {
                            display: inline-block; width: 85%; height: 1px; vertical-align: middle;
                            background: #000; float: left; margin: 8px 0; position: relative;
                            &:after {
                                content:''; width: 10px; height: 10px; background: $c_red;
                                position: absolute; right: 0; @include borderRadius(50%); top: -4px;
                            }
                        }
                        i {font-style: normal;}
                    }
                }
                .dec_miles {
                    float: right; width: 44.2%; float: right;
                    h1 {text-transform: uppercase; margin-bottom: 25px;}
                }
            }
        }
    }
    .nav-direction {
        position: absolute; left: 0; right: 0; margin: auto; top: 0; bottom: 0; margin: auto;
        a {
            position: absolute; top: 0; bottom: 0; margin: auto; height: 125px;
            display: block; font-size: 32px; color: #000; font-weight: 700;
            &:before {
                width: 77px; height: 88px; border: none; font-size: 0; content: ''; margin-bottom: 10px;
                display: block; background: url('../images/material/arr_big_slide.png') no-repeat;
            }
            &.prev {
                left: 50px; 
                &:before {background-position: bottom right;}
            }
            &.next {
                right: 50px;
            }
            &.disable {
                display: none;
            }
        }
    }
}
.sec_meetOur {
    padding: 0 0 125px; background: url('../images/banner/bg_meet_our.jpg') no-repeat bottom center;
    .box_img {
        float: left;width: 69.16%; position: relative;
        .slider {
            position: relative; padding-top: 150px;
            .txt_meet {
                width: 80%; @include boxSizing(border-box); position: relative; z-index: 2;
                height: 600px; border: 3px solid #ccc; padding: 0 60px; background: #fff;
                .name {
                    width: 290px; top: 50%; position: relative; @include transform(translateY(-50%));
                }
                h4 {
                    font-size: 25px; font-weight: 700; color: #000; margin-bottom: 20px; padding-left: 60px;
                    text-transform: uppercase; background: url('../images/material/img_ico_devisi.png') no-repeat left top;
                    span {display: block; font-weight: 300; font-size: 18px; margin-top: 2px;}
                }
            p {margin-bottom: 0;}
            }
        }
        .img {position: absolute; right: -0; bottom: -3px; z-index: 3;}
        .slick-arrow {
            width: 34px; height: 31px; border: none; font-size: 0; cursor: pointer; right: -37%; @include transform(rotate(-90deg));
            @include transition( background-position ease-out 0.2s); position: absolute; bottom: 0;
            &.slick-prev {
                background: url('../images/material/arr_project_top.png') no-repeat top center; 
                &:hover {
                    background-position: bottom center; @include transition( background-position ease-out 0.2s);
                }
            }
            &.slick-next {
                background: url('../images/material/arr_project_btm.png') no-repeat bottom center; right: -45%;
                &:hover {
                    background-position: top center;  @include transition( background-position ease-out 0.2s);
                }
            }
        }
    }
    .right_meet {float: right; width:  30.83%; position: relative; text-align: right; padding-top: 150px;}
}
.sec_testimoni {
    position: relative; padding: 110px 0 0;
    .text_rotate {
        position: absolute; left: 0; @include transform(rotate(90deg)); width: 40px; top: 40%;
        h5 {
            font-size: 65px; font-weight: 300; color: #ebebeb; text-transform: uppercase;
        }
    }
    .pg_title {margin-bottom: 60px;}
    .block_testi {
        .sliderTesti {
            position: relative;
            &:before, .line2  {
                content:'';  height: 2px; background: #ebebeb; width: 100%; position: absolute; left: -50%;
            }
            .line2 {bottom: 0;}
        }
        .textTestimoni {
            float: left; width: 35%;
            .slide {
                .txt_testimoni {
                    margin-top: 35%;
                    p {position: relative;}
                    .round_img {
                        position: relative; margin-top: 50px;
                        .fto {
                            width: 93px; height: 93px; border: 1px solid #000; @include borderRadius(50%);
                            overflow: hidden; display: inline-block; vertical-align: middle;
                            img {display: block; margin: 0 auto;}
                        }
                        .by {
                            display: inline-block; vertical-align: middle; margin-left: 10px;
                            p {margin-bottom: 0; font-size: 18px;}
                        }
                    }
                }
            }
        }
        .Imgtestimoni {
            float: right; width: 50%;
            .img {
                figure {margin: 0; padding: 0;}
            }
            .slick-arrow {
                width: 34px; height: 31px; border: none; font-size: 0; cursor: pointer; @include transition( background-position ease-out 0.2s);
                position: absolute; left: -100%; top: 70px; @include transform(rotate(-90deg));
                &.slick-prev {
                    background: url('../images/material/arr_project_top.png') no-repeat top center;
                    &:hover {
                        background-position: bottom center; @include transition( background-position ease-out 0.2s);
                    }
                }
                &.slick-next {
                    background: url('../images/material/arr_project_btm.png') no-repeat bottom center; left: -92%;
                    &:hover {
                        background-position: top center;  @include transition( background-position ease-out 0.2s);
                    }
                }
            }
        }
    }
}

.breadcrumb {
    margin: -15px 0 60px 35px; position: relative; z-index: 3;
    a {
        font-size: 14px; color: $c_333; margin-right: 6px; padding-right: 5px;  display: inline-block;
        border-right: 1px solid $c_333; font-weight: 300; text-transform: uppercase;
        &:last-child {border-right: none; margin-right: 0; padding-right: 0;}
    }
    .pos {font-size: 20px; font-weight: 700;}
}
h5 {font-weight: 600;}
.top_fil {
    padding: 25px 30px; background: #fff; margin: 20px 0 10px; border: 1px solid $c_e1;
    .sort {
        display: inline-block; margin-right: 100px;
        &:last-child {margin-right: 0;}
        label {font-size: 18px; font-weight: 700; text-transform: uppercase; display: inline-block; vertical-align: middle;}
        select {font-size: 18px; color: $c_999; border: none; width: 145px; background: url('../images/material/arr_prod.png') no-repeat 98% center;}
        &.dt {
            select {width: 100px;}
        }
        &.med {
            select {width: 165px;}
        }
        .cover_combo {
            padding: 10px; background: url("../images/material/arr_prod.png") no-repeat 98% center;
            .inner_combo {font-size: 18px; color: $c_999; padding-right: 5px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
        }
    }
    .right {
        form {
            border: 1px solid #dadada; display: inline-block;
            @include placeholder {color: #dadada;}
            input[type="text"] { 
                font-size: 15px; color: #dadada; border: none; width: 200px; @include boxSizing(border-box);
                padding: 10px 10px 10px 20px;
            }
            input[type="submit"] { 
                background: url('../images/material/src_ico.png') no-repeat left center; 
                border: none; width: 35px; cursor: pointer;
            }
        }

    }
}
.asside {
    width: 265px; float: left; border: 1px solid $c_e1;
    background: #fff;
    .filter {
        padding: 0 20px 25px; border-bottom: 1px solid $c_e1;
        h6 {
            font-size: 18px; font-weight: 700; text-transform: uppercase; cursor: pointer;
            position: relative; padding: 25px 0 0;
            .arr {
                display: inline-block; position: absolute; right: 0; width: 14px; height: 9px;
                background: url('../images/material/arr_prod.png') no-repeat center center;
                @include transition(all linear 0.3s);
            }
            &.act {
                .arr {@include transform(rotate(-180deg)); @include transition(all linear 0.3s);}
            }
        }
        &.price {
            background: #000;
            h6 {
                color: #fff;
                .arr {background: url('../images/material/arr_prod_white.png') no-repeat center center;}
            }
        }
        .drop_filt {
            padding-top: 20px;
            a {
                display: block; font-size: 14px; color: #000; text-transform: uppercase; padding: 20px 0;
                position: relative;
                &:before {
                    content:''; width: 4px; height: 0; position: absolute; left: -20px; display: block; 
                    background: #000; @include transition(height ease-out 0.2s); top: -16px;
                }
                &:hover,&.active {
                    &:before {
                        @include transition(height ease-out 0.2s); height: 50px;
                    }
                }
            }
        }
        .box_price {
            @extend .after_clear;
            .col {
                float: left; width: 108px;
                label {display: block; color: #fff; font-size: 13px; display: inline-block; margin-bottom: 5px;}
                input{
                    @include boxSizing(border-box); width: 100%; border: none; font-size: 14px;
                    font-family: 'Exo', sans-serif; color: $c_999; @include borderRadius(0);
                }
                &:nth-child(2) {float: right;}
            }
        }
        #price_range {      
            margin-top: 30px; border: none; height: 5px; margin-bottom: 10px;
            .ui-corner-all {
                border: none; background: url('../images/material/arr_range.png') no-repeat center center;
                width: 21px; height: 21px; top: -8px;
            }
            .ui-widget-header {background: $c_red; height: 100%; top: 0;}
        }
        &:last-child {border-bottom: none;}
    }
}
.content_right {float: right; width: 925px; float: right;}
.col_list {
    border: 1px  solid $c_e1; border-bottom: none; @extend .after_clear;
    .col {
        float: left; border-right: 1px solid $c_e1; width: 25%; position: relative; overflow: hidden;
        @include boxSizing(border-box); background: #fff; border-bottom: 1px solid $c_e1;
        &:nth-child(4n) {border-right: none;}
        .img {
            overflow: hidden;
            img {display: block;}
            .flag {
                position: absolute; width: 80px; height: 50px; background: url('../images/material/flag_disc.png') no-repeat; background-size: 100%;
                top: 0px; right: 0;
                span {color: #fff; font-weight: 700; position: absolute; bottom: 10px; left: 6px; font-size: 16px; display: inline-block; width: 37px; overflow: hidden; text-align: center;}
            }
        }
        .ket_prod {
            padding: 0 20px 25px; text-align: center; text-transform: uppercase;
            span {font-size: 14px; color: $c_999;}
            h6 {font-weight: 700; font-size: 16px; margin: 6px 0;}
            h5 {font-weight: 700; color: $c_red; font-size: 18px; margin-top: 6px; margin-bottom: 0;}
            .disc {font-weight: 500; color: $c_333; text-decoration: line-through;}
        }
        .more_det {
            display: block; background: $c_333; color: #fff; text-transform: uppercase; font-weight: 700;
            padding: 30px 0; text-align: center; font-size: 16px; position: absolute; left: 0; right: 0;
        }
    }
    &.listProject {
        border: none;
        .col {
            width: 33.333%; border: none; padding: 40px 0; position: relative;
            a {display: block;}
            .img {
                position: relative;
                img {display: block; margin: 0 auto;}
                &:after {
                    content:''; background: rgba(255,255,255,0.5); display: block; margin: auto; left: 0; right: 0;
                    top: 0; bottom: 0; position: absolute; @include transition(background ease-out 0.3s);
                }
            }
            h6 {
                font-size: 18px;  color: #999; font-weight: 500; text-align: center; text-transform: uppercase; 
                margin-top: 25px; @include transition(color ease-out 0.2s);
            }
            &:hover {
                .img {
                    &:after {
                        background: none; @include transition(background ease-out 0.3s);
                    }
                }
                h6 {color: #000; @include transition(color ease-out 0.2s);}
            }
        }
    }
    &.col-5 {
        .col {
            width: 20%;
            &:nth-child(4n) {border-right: 1px solid $c_e1;}
            &:nth-child(5n) {border-right: none;}
         }
    }
}
.related {
    .col_list {
        border: none;
        .col {
            border: 1px  solid $c_e1; width: 24.2%; margin-right: 1.06667%;
            &:nth-child(4) {margin-right: 0;}
            .img {
                img {margin: 0 auto;}
            }
        }
    }
}
.load_more {
    font-size: 18px; font-weight: 700;  display: block; padding: 0 0 0 30px; line-height: 24px;
    background: url('../images/material/load_more.png') no-repeat left top; color: #000; text-transform: uppercase;
    width: 110px; margin: 20px auto 0;
    &:hover {
        text-decoration: underline;
    }
}
.scrolltoTop {
    position: fixed; width: 30px; height: 39px; bottom: 100px; right: 30px;background: url('../images/material/scrolltotop.png') no-repeat;
    background-size: 30px !important; cursor: pointer; display: none;
}
.content_prod {
    &.full {
        .content_right {
            float: none; width: 100%; background: #fff;
            &.thanks {background: transparent;}
        }
    }
    h5 {font-weight: 600; text-transform: uppercase; margin-bottom: 20px;}
}
.qty_input {
    border: 1px solid $c_e1; padding: 0 30px; position: relative; width: 112px;
    display: block; @include boxSizing(border-box);
    input {
        width: 50px; border: none; padding: 0;  font-size: 22px; text-align: center; @include borderRadius(0);
        border-left: 1px solid $c_e1; border-right: 1px solid $c_e1; line-height: 30px;
    }
    button {
        position: absolute; width: 30px; top: 0; background: none; border: none; font-size: 24px; line-height: 26px;
        color: #ccc;  font-weight: 400; cursor: pointer; padding: 0;
        &.min {left: 0; font-size: 30px; line-height: 24px;}
        &.plus {right: 0;}
        &:hover {color: #aaa;}
    }
}
.zoomContainer {display: none;}
#fbplus-close {
    width: 35px !important; height: 35px !important; background: #fff !important; top:-25px !important; right: 0 !important;

    &:after {
        content:''; width: 18px; height: 17px; display: block;  background: url("../images/material/remove_cart.png") no-repeat bottom center;
        position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto;
    }
}
#fbplus-left-ico, 
#fbplus-right-ico {
    width: 30px !important; height: 22px !important;
    @include transform(rotate(-90deg)); left: auto !important; right: auto !important;
}
#fbplus-left-ico { left: 30px !important; background: url("../images/material/arr_project_top.png") no-repeat bottom center !important; background-size: 24px !important; }
#fbplus-right-ico {right: 30px !important; background: url("../images/material/arr_project_btm.png") no-repeat top center !important; background-size: 24px !important; }
.detail_prod {
    @extend .after_clear; padding: 85px 0 70px; border-bottom: 2px solid $c_e1;
    .img_big {
        float:  left; padding-left: 60px; width: 435px;
        .img {
            position: relative;
            img {width: 100%;}
        }
        .list_img {
            height: 435px; overflow: hidden; position: relative; width: 435px;
            .fancybox {position: absolute;z-index:1; display: block;opacity: 0;} 
            .in{z-index: 2;}
        }
        .thumb {
            margin-top: 20px;
            a {
                display: inline-block; width: 100px; border: 1px solid $c_e1; margin-right: 5px;
                &:last-child() {margin-right: 0;}
                &.active {border-color: $c_red;}
            }
        }
    }
    .dec_prod {
        width: 610px; float: right;
        h5 {text-transform: uppercase; margin-bottom: 30px;}
        ul {
            width: 85%;
            li {
                position: relative; font-weight: 200; font-size: 22px;
                padding-left: 40px; margin-bottom: 20px;
                &:before {
                    content:''; width: 8px; height: 8px; background: $c_red; position: absolute; left: 15px;
                    top: 6px; @include borderRadius(50%);
                }
            }
        }
        .pqt {
            @extend .after_clear; padding-right: 20px; margin: 50px 0;
            .ket {
                float: left; width: 32%; margin-right: 2%;
                &:last-child {margin-right: 0;}
                label {font-size: 18px; font-weight: 600; display: block; margin-bottom: 20px; text-transform: uppercase;}
                h5 {margin: 0; font-weight: 400; line-height: 30px;}
                &.tot {
                    h5 {color: $c_red;  font-weight: 700;}
                }
            }
        }
        .btn {line-height: 45px; @include borderRadius(5px); width: 270px; padding: 0;}
    }
}
.decription {
    padding: 50px 0 30px; border-bottom: 2px solid $c_e1;
    p {font-size: 20px; line-height: 30px;  font-weight: 300;}
}
.related {
    padding-top: 50px
}
.cart_pg {
    padding: 50px 60px;
    h4 {
        font-size: 20px;  font-weight: 700; text-transform: uppercase; margin-bottom: 30px;
        span {text-transform: capitalize; color: $c_red;}
    }
    .shopCart_list {
        .item {
            @extend .after_clear; border: 1px solid $c_e1; position: relative; margin-bottom: 20px;
            .blok {
                display: table-cell; vertical-align: middle; padding: 20px;
                @include boxSizing(border-box);
                &.img {width: 200px; border-right: 1px solid $c_e1;}
                &:nth-of-type(2) {
                    width: 300px; padding: 0 40px;
                    h5 {line-height: 24px;}
                }
                &:nth-of-type(3) {
                    width: 200px;
                    h5 {font-weight: 400;}
                }
                &:nth-of-type(5) {
                    width: 200px;
                }
                label {font-size: 16px; color: $c_ccc; text-transform: uppercase; margin-bottom: 22px; display: inline-block;}
                h5 {margin-bottom: 0; font-weight: 700;}
                h6 {font-size: 18px; font-weight: 600; color: $c_ccc; margin-bottom: 22px;}
                .qty_input {
                    input {font-size: 18px;}
                }
            }
            .rmove {
                width: 25px; height: 23px; position: absolute; right: 20px; top: 20px; display: inline-block; background-size: 24px !important;
                background: url('../images/material/remove_cart.png') no-repeat top center; cursor: pointer;
                &:hover {
                    background-position: bottom center;
                }
            }
        }
    }
    .ckout {
        .row {
            @extend .after_clear; margin-bottom: 15px;
            p {font-size: 18px; color: $c_ccc;}
            &:first-child {
                margin-bottom: 35px;
                p {margin-bottom: 0;}
            }
            .col {
                float: left; width: 49%;
                &:nth-child(2){float: right;}
                .btn{
                    &.right {width: 300px; padding: 0; margin-top: 20px;}
                }
            }
            label {display: block; font-size: 18px; color: $c_333; margin-bottom: 3px;}
            input[type="text"], input[type="email"],
            select, textarea  {
                width: 100%; @include boxSizing(border-box); font-size: 18px; padding: 10px 20px; @include transition(all ease-out 0.3s);
                &:focus {
                    @include transition(all ease-out 0.3s); border-color: $c_red;
                }
            }
            textarea {height: 200px;}

        }
        .btm_cart {
            a.back_shop {margin-top:10px;}
        }
        .btn {cursor: pointer; border: none; line-height: 45px;}
        &.apply {
            .input_file { 
                position: relative; margin-bottom: 30px;
                input[type="file"] {
                    position: absolute; top: 0;left: 0; z-index: 1; opacity: 0; min-width: 100%; padding: 10px 0; cursor: pointer;
                }
                height: 41px; border: 1px solid #D8D8D8; background: #fff;
                @include borderRadius(5px);
                label {font-size: 13px; color: $c_999; font-style: italic; position: absolute; bottom: -24px; left: 0;}
                span {font-size: 18px; line-height: 40px; display: block; padding: 0 20px;}
            }
            input[type=submit].btn {
                padding: 0; width: 300px;
            }
            .row {
                &:first-child { margin-bottom: 15px; }
                &:last-child {margin-top: 40px;}
            }
        }
    }
}
.btm_cart  {
    margin-top: 40px;
    .back_shop {
        color: $c_ccc; font-size: 16px;  position: relative;@include transition(color ease-out 0.2s);
        &:before {
            content:''; width: 8px; height: 12px; display: inline-block; margin-right: 10px;
            background: url('../images/material/arr_bk.png') no-repeat left center; @include transition(all ease-out 0.2s);
        }
        &:hover {
            color: #000; @include transition(color ease-out 0.2s);
            &:before {@include transition(all ease-out 0.2s); background-position: right center;}
        }
    }
    .right {
        text-align: right;
        h6 {
            font-size: 18px; font-weight: 600; margin-bottom: 30px;
            span {font-size: 28px;  color: $c_red; font-weight: 700;}
        }
        .btn {width: 300px; padding: 0; cursor: pointer;}
    }
}
.text_thanks {
    text-align: center; width: 960px; margin: 13.6% auto;
    h1 {font-size: 90px; font-weight: 500; margin-bottom: 50px;}
    p {font-size: 28px; line-height: 38px; margin-bottom: 30px; letter-spacing: 2.5px;}
    .btn {width: 300px; margin: 0 auto;}
    &.karir {
        margin: 15% auto;
        h1 {font-size: 60px; margin-bottom: 40px; font-weight: 700;}
        p {font-size: 30px; letter-spacing: normal; margin-bottom: 40px;}
    }
}
.left_project {
    margin-left: 200px;
    .project_content {
        width: 1200px; float: left; @extend .after_clear; margin-top: 60px;
        .slide_project1 {
            .item {
                @extend .after_clear;
                .images {float: left;  width: 58.35%;}
                .dec_project {
                    width: 37.5%; float: right;
                    h1 {margin-bottom: 25px; line-height: 45px;}
                    .rd_more {
                        font-size: 18px; text-transform: uppercase; color: $c_999; font-weight: 700; position: relative; display: inline-block;
                        padding-left: 27px; line-height: 30px; @include transition(color ease-out 0.2s); margin-top: 10px;
                        &:before {
                            content:''; width: 22px; height: 22px; display: inline-block; position: absolute; left: 0; top: 0;
                            background: url('../images/material/more_rd.png') no-repeat bottom center;
                        }
                        &:hover,
                        &.act {
                             @include transition(color ease-out 0.2s); color: #000;
                             &:before {
                                 background-position: top center;
                             }
                        }

                    }
                }
            }
        }
        .more_project {display: inline-block;}
    }
    .thumb_project {
        float: right; width: 140px; margin-top: -50px;
        .list_thumb {
            text-align: center; padding: 60px 0;
            .slick-list{padding: 100px 0 !important;}
            .thumb {
                width: 95px !important; margin: 5px 20px; @include transition(all ease-out 0.2s); position: relative; z-index: 2; height: 95px;
                background-size: 100% !important; display: block; border: none;
                &:after {
                    content:''; position: absolute; right: 0; bottom: 0; left: 0; top: 0; margin: auto;
                    background: rgba(255,255,255,0.7); @include transition(all ease-out 0.2s);
                }
                &.slick-center{
                    @include transform(scale(1.4));@include transition(all ease-out 0.2s); z-index: 5;
                    &:after {display: none; @include transition(all ease-out 0.2s);}
                }
            }
            
            .slick-arrow {
                width: 34px; height: 31px; border: none; font-size: 0; cursor: pointer; @include transition( background-position ease-out 0.2s);
                position: absolute; left: 0; right: 0; margin: auto;
                &.slick-prev {
                    background: url('../images/material/arr_project_top.png') no-repeat top center; top: 0;
                    &:hover {
                        background-position: bottom center; @include transition( background-position ease-out 0.2s);
                    }
                }
                &.slick-next {
                    background: url('../images/material/arr_project_btm.png') no-repeat bottom center; bottom: 0;
                    &:hover {
                        background-position: top center;  @include transition( background-position ease-out 0.2s);
                    }
                }
            }
        }
    }
    .more_project {display: none;}
}
.more_project {
    padding-left: 40px; font-size: 18px; text-transform: uppercase; color: #000; font-weight: 700; line-height: 27px; position: relative;
    background: url('../images/material/more_project.png') no-repeat left center; display: inline-block;
}
.pagging {
    text-align: center; margin-top: 100px;
    a {
        display: inline-block; width: 30px; line-height: 30px; font-size: 18px; color: #000; text-align: center;
        @include transition(all ease-out 0.2s);
        &:hover, &.active {
            font-weight: 700; color: #fff; background: url('../images/material/bg_pagging.png') no-repeat; background-size: 100%;
            @include transition(all ease-out 0.2s);
        }
    }
}

.detail_listPro {
    .slideSmall {
        margin: 30px 20px 0; position: relative;
        .thmb {
            width: 170px; margin: 0 15px; cursor: pointer; position: relative;
            &:after {
                background: rgba(255,255,255,0.6); display: block; left: 0; right: 0; top: 0; bottom: 0; position: absolute;
                content: ''; @include transition(opacity ease-out 0.3s); opacity: 1;
            }
            &.slick-current,
            &:hover {
                &:after {opacity:0; @include transition(opacity ease-out 0.3s);}
            }

        }
        .slick-arrow {
            width: 34px; height: 31px; border: none; font-size: 0; cursor: pointer; @include transition( background-position ease-out 0.2s);
            top: 0; bottom: 0; margin: auto; position: absolute; @include transform(rotate(-90deg));
            &.slick-prev {
                background: url('../images/material/arr_project_top.png') no-repeat top center; left: -75px;
                &:hover {
                    background-position: bottom center; @include transition( background-position ease-out 0.2s);
                }
            }
            &.slick-next {
                background: url('../images/material/arr_project_btm.png') no-repeat bottom center; right: -75px;
                &:hover {
                    background-position: top center;  @include transition( background-position ease-out 0.2s);
                }
            }
        }
    }
    .text_detailProj {
        margin: 60px 0 45px;
    }
}

.news_pg {
    .big_news {
        .hdline {
            .img {
                padding: 35px 0; width: 57.5%;
            }
            .dec_news {
                width: 42.5%; float: right; background: #fff; @include boxShadow(5px 0px 20px -2px #eee);
                padding: 80px 38px; @include boxSizing(border-box);
                h1 {margin-bottom: 10px; line-height: 45px; text-transform: uppercase; padding-right: 50px;}
                p {max-height: 210px; overflow: hidden;}
            }
        }
    }
    .date {font-size: 18px;  color: $c_999; display: block; margin-bottom: 35px;}
    .rd_more {
        font-size: 18px; text-transform: uppercase; color: $c_999; font-weight: 700; position: relative; display: inline-block;
        padding-left: 27px; line-height: 30px; @include transition(color ease-out 0.2s);
        &:before {
            content:''; width: 22px; height: 22px; display: inline-block; position: absolute; left: 0; top: 0;
            background: url('../images/material/more_rd.png') no-repeat bottom center;
        }
        &:hover,
        &.act {
             @include transition(color ease-out 0.2s); color: #000;
             &:before {
                 background-position: top center;
             }
        }

    }
    .grid_news {
        width:1230px;margin:0; margin-top: 135px;
        .grid_item {
            width:25%; height: 575px; margin-bottom: 30px; cursor: pointer;
            .img {
                width: 280px; height: 255px; position: absolute; overflow: hidden;
                @include filter(grayscale(100%));
            }
            .dec {
                width: 280px; height: 320px; position: absolute; bottom: 0; border: 1px solid $c_e1;
                padding: 22px 32px; @include boxSizing(border-box);
                .date {margin-bottom: 45px;}
                a {position: absolute; bottom: 22px; left: 32px;}
                h5 {
                    line-height: 28px; text-transform: uppercase; padding-top: 25px; position: relative;
                    &:before {
                        content: ''; height: 4px; width: 40px; position: absolute; top: 0; left: 0;
                        background: $c_red;
                    }
                }
            }
            &:nth-of-type(even) {
                .img {bottom: 0;}
                .dec {top: 0;}
            }
            &:nth-of-type(6n),&:nth-of-type(10n),&:nth-of-type(14n) {
                .img {top: 0; bottom: auto}
                .dec {bottom: 0; top: auto;}
            }
            &:nth-of-type(7n),&:nth-of-type(11n),&:nth-of-type(12n),&:nth-of-type(15n) {
                .img {bottom: 0; top: auto;}
                .dec {top: 0; bottom: auto;}
            }
            &:nth-of-type(2),&:nth-of-type(4) {margin-top: 120px;}
            &:hover {
                .img {
                    @include filter(grayscale(0));
                }
            }
        }
    }
    .load_more {
        margin-top: 80px; background: url('../images/material/more_project.png') no-repeat left center; line-height: 30px;
        padding-left: 35px;
    }
}

.detailNews {
    padding-bottom: 40px; margin-bottom: 60px; border-bottom: 2px solid $c_e1;
    .images {margin-bottom: 50px;}
    .date {margin-bottom: 10px;}
    h1 {margin-bottom: 25px;}
}
.dot {
    font-size: 30px; font-weight: 500; padding-left: 30px; position: relative;
    margin-bottom: 40px;
    &:before {
        content:''; width: 10px; height: 10px; background: $c_red; @include borderRadius(50%); position: absolute;
        left: 0; top: 0; bottom: 0; margin: auto;
    }
}
h3.wrapper {@include boxSizing(border-box);}
.slide_recomend {
    position: relative;

    .slide {
        width: 760px; position: relative; cursor: pointer;
        a {display: block;}
        &:after {
            content:''; position: absolute; left: 0; right: 0; display: block; background: rgba(255,255,255,0.7);
            top: 0; bottom: 0; opacity: 1; margin: auto; @include transition(all linear 0.9s);
        }
        &.slick-center {
            &:after {
                opacity: 0;@include transition(all linear 0.9s);
            }
        }
    }
    .slick-arrow {
        width: 34px; height: 31px; border: none; font-size: 0; cursor: pointer; @include transition( background-position ease-out 0.2s);
        position: absolute; top:0; bottom: 0; margin: auto; z-index: 8; @include transform(rotate(-90deg));
        &.slick-prev {
            background: url('../images/material/arr_project_top.png') no-repeat bottom center;
            left: 20%;
        }
        &.slick-next {
            background: url('../images/material/arr_project_btm.png') no-repeat top center;
            right: 20%;
        }
    }
}
.SlideText {
    width: 760px; display: block;margin: 40px auto 0; text-align: center;
    h4 {margin-bottom: 10px; position: relative;}
    .date {position: relative;}
    p {position: relative;}

}

.listCareer {
    width: 1080px; margin-top: 110px; z-index: 2; position: relative;
    .list {
        padding-bottom: 40px;border-bottom: 1px solid $c_e1; margin-bottom: 40px; width: 100%; display: table;
        div {
            float: none;padding: 0 30px; @include boxSizing(border-box); display: table-cell; vertical-align: middle;
            h5 {font-size: 20px; font-weight: 700; margin-bottom: 10px;}
            p {margin-bottom: 0;}
            &.job {
                padding-left: 0; width: 35%;
                span {display: block; font-size: 16px; color: $c_333; font-weight: 400; font-style: italic;}
            }
            &.title_file {
                span {display: block; font-size: 16px; color: $c_333; font-weight: 400; font-style: italic;}
            }
            &.pos {padding-left: 50px; width: 33%;}
            &.submit {
                padding-right: 0; width: 32%;
                text-align: right;
                .btn {width: 240px; display: inline-block; line-height: 60px;}
            }
        }
    }
}
.img_side_carrer {position: absolute; bottom: 55px; right: 0; z-index: 1;}
.detailCareer {
    margin-top: 150px;
    ul {
        li {font-size: 16px; color: #666;}
    }
    .ket_career {
        p {font-size: 16px;  color: #666; line-height: 24px;}
        .btn {width: 300px; line-height: 45px; margin-top: 30px;}
    }
}
.top_career {
    padding-bottom: 30px; margin-bottom: 30px; border-bottom: 1px solid $c_e1;
    h5 {float: left; font-size: 20px; font-weight: 700;}
    .loc {margin: 0; float: right; font-size: 18px;}
}

#map_contact {height: 570px;}
.map_content {
    width: 200px;
    h4 {font-size: 16px; font-weight: 700; margin-bottom: 5px;}
    p {font-size: 12px; margin: 0; line-height: 16px;}
}
.contact_sec {
    margin-top: -125px;
    h2 {
        font-size: 30px; font-weight: 700; text-transform: uppercase;padding-left: 20px; position: relative;
        font-style: italic;
        &:before {
            content:''; width: 8px; height: 8px; @include borderRadius(50%);  display: block; left: 0; top: 10px;
            position: absolute; background: $c_red;
        }
    }
    .list_location {
        padding-bottom: 50px; border-bottom: 1px solid $c_e1; margin: 120px 0 90px; @extend .after_clear;
        .location {
            float: left; width: 31%; margin-right: 3.5%; margin-bottom: 50px; cursor: pointer;
            h2 {margin-bottom: 20px;}
            p {
                font-size: 18px; line-height: 26px; font-weight: 500;
                &.dt {
                    margin-bottom: 5px;
                    span {display: inline-block; width: 80px;}
                    a {color: #000;}
                }
            }
            &:nth-child(3n) {margin-right: 0;}
        }
    }
    .contact_form {
        .formContact {
            display: block;position: relative;width: 38.75%;
            p {font-size: 20px; font-weight: 300;}
            .row {
                margin-bottom: 20px;
                label {
                    font-size: 16px; display: block; margin-bottom: 5px;
                    i {color: $c_red;}
                }
                input[type="text"], 
                input[type="email"],
                textarea {
                    font-size: 16px; width: 100%; @include boxSizing(border-box); @include transition(all ease-out 0.3s);
                    &:focus {@include transition(all ease-out 0.3s); border-color: #000;}
                }
                textarea {height: 85px;}
                input[type="submit"],button,input[type="button"] {padding: 0; display: block; width: 100%; line-height: 45px;}

                &:last-child {margin-top: 25px;}
            }
        }
        .img.right {width: 50.84%;}
    }
}

/* footer
----------------------------------------------------------------------------------------------*/
footer {
    position: absolute; bottom: 0; left: 0; right: 0; left: 0; height: 60px; background: #000;
    text-align: center; display: block;
    .foot {
        display: inline-block; margin: 10px 0;
        ul {
            li {
             display: inline-block; margin: 0 5px;
             a {
                font-size: 13px; font-weight: 300; color: #fff; text-transform: uppercase; @include transition(color ease-out 0.2s);
                &:hover {color: $c_red;  @include transition(color ease-out 0.2s);}
            }
         }
        }
        &.left {
            ul {
                li {
                    a {
                        display: inline-block; height: 35px; width: 35px; border: 1px solid #fff;
                        @include borderRadius(50%);@include transition(border-color ease-out 0.2s);
                        &:hover {@include transition(border-color ease-out 0.2s); border-color: $c_red;}
                    }
                }
            }
        }
        &.center {
            display: inline-block; margin:23px 0;
            ul {
                li {
                    a {padding-right: 15px; border-right: 1px  solid #fff;}
                    &:first-child {
                        a {padding-left: 0;}
                    }
                    &:last-child {
                        a {padding-right: 0; border-right: none;}
                    }
                }
            }
        }
        &.right {
            margin: 23px 0;
            ul {
                li {
                    a {
                        padding-right: 15px; border-right: 1px solid #fff;
                        font-size: 13px; font-weight: 300; color: #fff; text-transform: uppercase; 
                        @include transition(color ease-out 0.2s);
                       
                        &:hover {color: $c_red;  @include transition(color ease-out 0.2s);}
                    }
                    &:last-child {
                        a {margin-right: 0; padding-right: 0; border-right: none;}
                    }
                }
            }
        }
    }
}



.fancybox-toolbar {
    display: block; opacity: 1;visibility: visible;
    .fancybox-button--play,
    .fancybox-button--fullscreen,
    .fancybox-button--thumbs {display: none;}
}
.fancybox-arrow--right,
.fancybox-arrow--left {
    display: block !important; width: 40px !important; height: 40px !important;
    &[disabled] {opacity: 0 !important;}
}
.fancybox-arrow:after {opacity: 1 !important;}

.fancybox-arrow--right:after {
    background: rgba(255,255,255,0.4) url("../images/material/arr_pop_02.png") no-repeat center !important; width: 40px !important; height: 40px !important;
    background-size: 30px !important;
}
.fancybox-arrow--left:after {
    background: rgba(255,255,255,0.4) url("../images/material/arr_pop_02.png") no-repeat center !important; width: 40px !important; height: 40px !important;
    background-size: 30px !important;
}

/*.fancybox-show-nav .fancybox-arrow {opacity: 1 !important;}*/
.fancybox-show-nav .fancybox-arrow[disabled] {display: none;}

.fancybox-button--close:after, .fancybox-button--close:before {display: none !important;}
.fancybox-button--close {background: #fff url('../images/material/ico_close.png') no-repeat center !important; background-size: 30px !important;}